import React from 'react';
import { Wrapper, Content } from './style';

const Loader = () => {
  return (
    <Wrapper>
      <Content>Loading</Content>
    </Wrapper>
  );
};

export default Loader;
