import styled, { css } from 'styled-components';
import { breakpoints, layout, layers } from '../../theme';

export const Wrapper = styled.div`
  bottom: 0;
  left: 0;
  opacity: ${props => (props.isActive ? 1 : 0)};
  position: absolute;
  right: 0;
  top: 0;
  transition: ${props =>
    props.isActive
      ? 'opacity 0.5s ease-out 0.3s, visibility 0.5s ease-out 0.3s'
      : 'opacity 0.3s ease-in, visibility 0.3s ease-in'};
  visibility: ${props => (props.isActive ? 'visible' : 'hidden')};

  ${props =>
    props.isTwoUp
      ? css`
          @media ${breakpoints.MOBILE_MQ} {
            left: -${layout.SPACING_X / 2}rem;
            right: -${layout.SPACING_X / 2}rem;
          }
        `
      : null}
`;

export const ImageWrapper = styled.div`
  bottom: 0;
  left: ${props => (props.right ? 'calc(50% + 9px)' : 0)};
  position: absolute;
  right: ${props => (props.left ? 'calc(50% + 9px)' : 0)};
  top: 0;

  @media ${breakpoints.MOBILE_MQ} {
    left: ${props => (props.right ? 'calc(50% + 4px)' : 0)};
    right: ${props => (props.left ? 'calc(50% + 4px)' : 0)};
  }
`;

export const VideoWrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  left: ${props => (props.right ? 'calc(50% + 9px)' : 0)};
  position: absolute;
  right: ${props => (props.left ? 'calc(50% + 9px)' : 0)};
  top: 0;

  @media ${breakpoints.MOBILE_MQ} {
    bottom: 30%;
    left: ${props => (props.right ? 'calc(50% + 4px)' : 0)};
    right: ${props => (props.left ? 'calc(50% + 4px)' : 0)};
    top: 30%;
  }

  iframe {
    height: 100%;
    left: 0;
    pointer-events: auto;
    position: absolute;
    top: 0;
    width: 100%;

    @media ${breakpoints.MOBILE_MQ} {
      z-index: 2;
    }
  }
`;
