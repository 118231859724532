import styled, { keyframes } from 'styled-components';

const dots = keyframes`
  0% {
    content: '';
  }

  33% {
    content: '.';
  }

  66% {
    content: '..';
  }

  100% {
    content: '...';
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Content = styled.div`
  &:after {
    content: '';
    animation: ${dots} 2s linear infinite alternate;
  }
  &:before {
    content: '';
    opacity: 0;
    animation: ${dots} 2s linear infinite alternate;
  }
`;
