import React, { useState, useEffect } from 'react';
import Image from 'gatsby-image';
import { Wrapper, ImageWrapper, VideoWrapper } from './style';
import Loader from '../Loader';

const Slide = ({ slide, isActive }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingLeft, setIsLoadingLeft] = useState(true);
  const [isLoadingRight, setIsLoadingRight] = useState(true);

  return (
    <Wrapper isActive={isActive} isTwoUp={slide.imageLeft || slide.imageRight}>
      {slide.vimeoId ? (
        <VideoWrapper>
          <Loader />
          <iframe
            src={
              isActive
                ? `https://player.vimeo.com/video/${slide.vimeoId}?autoplay=1&byline=0&loop=1&playsinline=1&portrait=0&title=0`
                : null
            }
            frameBorder="none"
          />
        </VideoWrapper>
      ) : slide.image ? (
        <ImageWrapper>
          {isLoading ? <Loader /> : null}
          <Image
            onLoad={() => setIsLoading(false)}
            fluid={slide.image.fluid}
            imgStyle={{
              objectFit: 'contain',
            }}
            style={{
              bottom: 0,
              left: 0,
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          />
        </ImageWrapper>
      ) : slide.imageLeft || slide.imageRight ? (
        <>
          <ImageWrapper left>
            {isLoadingLeft ? <Loader /> : null}
            <Image
              onLoad={() => setIsLoadingLeft(false)}
              fluid={slide.imageLeft ? slide.imageLeft.fluid : null}
              imgStyle={{
                objectFit: 'contain',
                objectPosition: '100% 50%',
              }}
              style={{
                bottom: 0,
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            />
          </ImageWrapper>
          <ImageWrapper right>
            {isLoadingRight ? <Loader /> : null}
            <Image
              onLoad={() => setIsLoadingRight(false)}
              fluid={slide.imageRight ? slide.imageRight.fluid : null}
              imgStyle={{
                objectFit: 'contain',
                objectPosition: '0% 50%',
              }}
              style={{
                bottom: 0,
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            />
          </ImageWrapper>
        </>
      ) : null}
    </Wrapper>
  );
};

export default Slide;
