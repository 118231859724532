import React, { useContext, useEffect, useState } from 'react';
import { CursorContent } from '../../store';
import Slide from '../Slide';
import {
  InnerWrapper as Wrapper,
  Images,
  Info,
} from '../../templates/Project/style';
import { InnerWrapper, Nav, Link, Slides } from './style';
import ProjectName from '../ProjectName';

const HomepageSlideshow = ({ projects }) => {
  const cursorContentDispatch = useContext(CursorContent.Dispatch);
  const [activeIndex, setActiveIndex] = useState(0);

  let previousIndex = activeIndex - 1;
  if (previousIndex < 0) previousIndex = projects.length - 1;

  let nextIndex = activeIndex + 1;
  if (nextIndex > projects.length - 1) nextIndex = 0;

  const activeProject = projects[activeIndex];

  useEffect(() => {
    console.log(`Selected Projects — ${activeIndex + 1}/${projects.length}`);
    cursorContentDispatch({
      type: 'setContentByType',
      contentType: 'slideshowCounter',
      content: `Selected Projects — ${activeIndex + 1}/${projects.length}`,
    });
    return () => {
      cursorContentDispatch({
        type: 'setContentByType',
        contentType: 'slideshowCounter',
        content: null,
      });
    };
  });

  return (
    <Wrapper>
      <Images>
        <InnerWrapper>
          {projects && projects.length > 1 ? (
            <Nav>
              <Link
                onMouseMove={() =>
                  cursorContentDispatch({
                    type: 'setActiveType',
                    activeType: 'slideshowCounter',
                  })
                }
                onMouseLeave={() =>
                  cursorContentDispatch({
                    type: 'setActiveType',
                    activeType: null,
                  })
                }
                onClick={evt => {
                  evt.preventDefault();
                  setActiveIndex(previousIndex);
                }}
              />
              <Link
                onMouseMove={() =>
                  cursorContentDispatch({
                    type: 'setActiveType',
                    activeType: 'slideshowCounter',
                  })
                }
                onMouseLeave={() =>
                  cursorContentDispatch({
                    type: 'setActiveType',
                    activeType: null,
                  })
                }
                onClick={evt => {
                  evt.preventDefault();
                  setActiveIndex(nextIndex);
                }}
              />
            </Nav>
          ) : null}
          <Slides>
            {projects
              ? projects.map((project, index) => {
                  const slide = project.slides[0];
                  return (
                    <Slide
                      key={slide.id}
                      isActive={index === activeIndex}
                      slide={slide}
                    />
                  );
                })
              : null}
          </Slides>
        </InnerWrapper>
      </Images>
      <Info>
        <p>
          Selected Projects — {activeIndex + 1}/{projects.length}
        </p>
        {activeProject ? (
          <h1>
            <ProjectName project={activeProject} /> —{' '}
            <Link
              to={`/${activeProject.category.slug}/${activeProject.slug}`}
              style={{
                cursor: 'pointer',
                pointerEvents: 'auto',
                position: 'relative',
                zIndex: 999,
              }}
            >
              View More
            </Link>
          </h1>
        ) : null}
      </Info>
    </Wrapper>
  );
};

export default HomepageSlideshow;
