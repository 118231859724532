import styled from 'styled-components';

import { layout, typography, breakpoints } from '../../theme';
import { Link as GatsbyLink } from 'gatsby';

export const InnerWrapper = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Nav = styled.nav`
  bottom: -${layout.SPACING_Y * 2 + typography.LINE_HEIGHT}rem;
  cursor: none;
  display: flex;
  left: -${layout.SPACING_X}rem;
  position: absolute;
  right: -${layout.SPACING_X}rem;
  top: -${layout.SPACING_Y * 2 + typography.LINE_HEIGHT}rem;
  z-index: 1;
`;

export const Link = styled(GatsbyLink)`
  cursor: none;
  flex-grow: 1;
`;

export const Slides = styled.div`
  bottom: 4vh;
  left: 4vw;
  position: absolute;
  right: 4vw;
  top: 4vh;
  z-index: 0;

  @media ${breakpoints.MOBILE_MQ} {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
`;
