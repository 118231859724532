import styled from 'styled-components';
import { breakpoints, layout, typography, layers } from '../../theme';

export const Wrapper = styled.article`
  bottom: ${layout.SPACING_Y}rem;
  left: ${layout.SPACING_X}rem;
  position: fixed;
  right: ${layout.SPACING_X}rem;
  top: ${layout.SPACING_Y + typography.LINE_HEIGHT + layout.SPACING}rem;
  z-index: ${layers.PAGE_CONTENT};

  @media ${breakpoints.MOBILE_MQ} {
    bottom: ${layout.SPACING_Y / 2}rem;
    top: ${layout.SPACING_Y / 2 + typography.LINE_HEIGHT + layout.SPACING}rem;
  }
`;

export const InnerWrapper = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Images = styled.div`
  flex-grow: 1;
  position: relative;
`;

export const Info = styled.header`
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: ${layout.SPACING}rem;
  text-align: center;

  p {
    display: none;

    html.touch & {
      display: block;
    }
  }
`;
