import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import HomepageSlideshow from '../../components/HomepageSlideshow';
import { Wrapper } from '../Project/style';

const Home = ({ pageContext, data }) => {
  const { metaData } = pageContext;
  const featuredProjects =
    get(data, 'contentfulSettings.featuredProjects') || [];

  return (
    <Wrapper>
      <Helmet title={`${metaData.siteName} | ${metaData.metaTitle}`} />

      <HomepageSlideshow projects={featuredProjects} />
    </Wrapper>
  );
};

export default Home;

export const homeQuery = graphql`
  query HomeQuery {
    contentfulSettings(siteName: { eq: "SCANDEBERGS" }) {
      featuredProjects {
        id
        name
        category {
          slug
        }
        client
        slug
        slides {
          ... on ContentfulProjectSlide1Up {
            id
            image {
              fluid(maxWidth: 3000, maxHeight: 3000, quality: 100) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
          ... on ContentfulProjectSlide2Up {
            id
            imageLeft {
              fluid(maxWidth: 3000, maxHeight: 3000, quality: 100) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
            imageRight {
              fluid(maxWidth: 3000, maxHeight: 3000, quality: 100) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
